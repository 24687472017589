function liveWin(id) {
    $.fn.DialogShow({ 'class': "dialog", "id": id });
};
var dialogFx;
// JavaScript Document
//定义Beautifier的构造函数
var DialogFx = function (ele, opt) {
    this.$element = ele,
        this.defaults = {
            'class': "dialog",
            'dialogFx': '0',
        },
        this.options = $.extend({}, this.defaults, opt)
};
//定义DialogFx的显示方法
DialogFx.prototype = {
    Show: function () {
        $("#" + this.options.id).removeClass("dialog--close");
        $("#" + this.options.id).addClass("dialog--open");
        $("#" + this.options.id).attr("style", "display:block");
        let isOver = $("body").height() < $("#" + this.options.id).find(".dialog__content").height() + 140;
        $("#" + this.options.id).find(".dialog__content").css("top", isOver ? "20px" : "140px")
        // var nowDialogHeight = $("#" + this.options.id).find(".dialog__content")[0].getBoundingClientRect().height;
        // if (nowDialogHeight.keepTwo(0) % 2 != 0) {
        //     $("#" + this.options.id).find(".dialog__content").height(parseInt(nowDialogHeight.keepTwo(0)) + 1)
        // }
    },
    Hidden: function () {
        $("#" + this.options.id).removeClass("dialog--open");
        $("#" + this.options.id).addClass("dialog--close");
        $("#" + this.options.id).attr("style", "display:none");
        // $("#" + this.options.id).find(".dialog__content").height('auto')
        if (this.$element[0]) {
            var thisP = $(this.$element[0]).parents("div.dialog")
            thisP.removeClass("dialog--open");
            thisP.addClass("dialog--close");
            thisP.attr("style", "display:none");
        }
    }
};
//在插件中使用DialogShow对象
$.fn.DialogShow = function (options) {
    //创建Beautifier的实体
    dialogFx = new DialogFx(this, options);
    $("#" + options.id).attr("class", "dialog dialog--open");
    if (options.dialogFx == "0") {
        return dialogFx.Hidden();
    } else {
        //调用其方法
        return dialogFx.Show();
    }
};

(function (window, document, undefined) {
    "use strict";
    var _mergeObj = function (to, from) {
        for (var p in from) {
            if (from.hasOwnProperty(p)) {
                to[p] = typeof from[p] === "object" ? _mergeObj(to[p], from[p]) : from[p];
            }
        }
        return to;
    };
    var _throttle = function (func, wait) {
        var _now = Date.now || function () {
            return new Date().getTime();
        };
        var context, args, result;
        var timeout = null;
        var previous = 0;
        var later = function () {
            previous = _now();
            timeout = null;
            result = func.apply(context, args);
            context = args = null;
        };
        return function () {
            var now = _now();
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0) {
                clearTimeout(timeout);
                timeout = null;
                previous = now;
                result = func.apply(context, args);
                context = args = null;
            } else if (!timeout) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };
    var _getScrollY = function () {
        return window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    };

    function _getElemY(elem) {
        var top = 0;
        while (elem) {
            top += elem.offsetTop;
            elem = elem.offsetParent;
        }
        return top;
    }
    var Headhesive = function (elem, options) {
        if (!("querySelector" in document && "addEventListener" in window)) {
            return;
        }
        this.visible = false;
        this.options = {
            offset: 300,
            classes: {
                base: "headhesive",
                stick: "headhesive--stick",
                unstick: "headhesive--unstick"
            },
            throttle: 250,
            onInit: function () { },
            onStick: function () { },
            onUnstick: function () { },
            onDestroy: function () { }
        };
        this.elem = typeof elem === "string" ? document.querySelector(elem) : elem;
        this.options = _mergeObj(this.options, options);
        this.init();
    };
    Headhesive.prototype = {
        constructor: Headhesive,
        init: function () {
            this.clonedElem = this.elem.cloneNode(true);
            this.clonedElem.className += " " + this.options.classes.clone;
            document.body.insertBefore(this.clonedElem, document.body.firstChild);
            if (typeof this.options.offset === "number") {
                this.scrollOffset = this.options.offset;
            } else if (typeof this.options.offset === "string") {
                this.scrollOffset = _getElemY(document.querySelector(this.options.offset));
            } else {
                throw new Error("Invalid offset: " + this.options.offset);
            }
            this._throttleUpdate = _throttle(this.update.bind(this), this.options.throttle);
            window.addEventListener("scroll", this._throttleUpdate, false);
            this.options.onInit.call(this);
        },
        destroy: function () {
            document.body.removeChild(this.clonedElem);
            window.removeEventListener("scroll", this._throttleUpdate);
            this.options.onDestroy.call(this);
        },
        stick: function () {
            if (!this.visible) {
                this.clonedElem.className = this.clonedElem.className.replace(new RegExp("(^|\\s)*" + this.options.classes.unstick + "(\\s|$)*", "g"), "");
                this.clonedElem.className += " " + this.options.classes.stick;
                this.visible = true;
                this.options.onStick.call(this);
            }
        },
        unstick: function () {
            if (this.visible) {
                this.clonedElem.className = this.clonedElem.className.replace(new RegExp("(^|\\s)*" + this.options.classes.stick + "(\\s|$)*", "g"), "");
                this.clonedElem.className += " " + this.options.classes.unstick;
                this.visible = false;
                this.options.onUnstick.call(this);
            }
        },
        update: function () {
            if (_getScrollY() > this.scrollOffset) {
                this.stick();
            } else {
                this.unstick();
            }
        }
    };
    window.Headhesive = Headhesive;
})(window, document);