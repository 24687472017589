/*地区类*/
function placeSSS(value, name) {
    this.value = value; //地区编码  
    this.name = name; //地名  
    /*地区类的PlaceType方法，返回值：String类型，表示地区类别，"p"代表省/直辖市、特别行政区，"c"代表市，"d"代表区/县。*/
    placeSSS.prototype.PlaceType = function () {
        var ac = parseInt(this.value);
        if (ac % 100 != 0) {
            return "d";
        } else if (ac % 10000 != 0) {
            return "c";
        } else {
            return "p";
        }
    }
    /*返回地点所属省编码*/
    placeSSS.prototype.ProvinceCode = function () {
        //整除10000得到省级编码（前2位数字）  
        var ac = parseInt(this.value);
        return Math.floor(ac / 10000);
    }
    /*返回地点所属市编码*/
    placeSSS.prototype.CityCode = function () {
        //整除100得到市级编码（前4位数字）  
        var ac = parseInt(this.value);
        return Math.floor(ac / 100);
    }
}

var provincesListSSS = new Array(); //省数组  
var citiesListSSS = new Array(); //市数组  
var districtsListSSS = new Array(); //区数组  
//遍历placesMap这个Json对象,根据key：value对创建place对象，并根据地区类型分类  
for (var key in placesMap) {
    var pl = new placeSSS(key, placesMap[key]);
    var ty = pl.PlaceType();
    if (ty == "p") {
        provincesListSSS.push(pl);
    }
    if (ty == "c") {
        citiesListSSS.push(pl);
    }
    if (ty == "d") {
        districtsListSSS.push(pl);
    }
}
var nationListSSS = []
for (var i = 0; i < provincesListSSS.length; i++) {
    nationListSSS.push({ name: provincesListSSS[i].name, value: provincesListSSS[i].ProvinceCode() * 10000, children: [] })
}
for (var j = 0; j < nationListSSS.length; j++) {
    var pCode = parseInt(nationListSSS[j].value / 10000); //获取省下拉列表被选取项的省编码  
    for (var m = 0; m < citiesListSSS.length; m++) {
        if (citiesListSSS[m].ProvinceCode() == pCode) {
            nationListSSS[j].children.push({ name: citiesListSSS[m].name, value: citiesListSSS[m].CityCode() * 100, children: [] })
        }
    }
}
for (var j = 0; j < nationListSSS.length; j++) {
    for (var m = 0; m < nationListSSS[j].children.length; m++) {
        var cCode = parseInt(nationListSSS[j].children[m].value / 100); //获取市下拉列表被选中项的市编码  
        for (var n = 0; n < districtsListSSS.length; n++) {
            if (districtsListSSS[n].CityCode() == cCode) {
                nationListSSS[j].children[m].children.push({ name: districtsListSSS[n].name, value: districtsListSSS[n].value * 1 })
            }
        }
    }
}
function initCityList(elem, data = nationListSSS) {
    //初始化省下拉选择列表  
    resetValSSS(elem)
    for (var i = 0; i < data.length; i++) {
        var { name, value, children } = data[i]
        $(`<option value='${value}'>${name}</option>`).appendTo(elem)
        $(elem).find("option").data(name, children);
    }
    $(elem).on('change', function () {
        var childrenList = $(this).find(":selected").data($(this).find(":selected").text())
        initCityList($(this).next('select'), childrenList)
        if ($(this).attr("onchange")) {
            eval($(this).attr("onchange"))
        }
    })
}
function resetValSSS(elem) {
    $(elem).find("option").not(":first").remove();
    if ($(elem).next('select').length != 0) {
        resetValSSS($(elem).next('select'))
    }
}