//location.origin兼容
if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
};
$("body").on("input", ".text-num", function(event) { //回车
    var event = event || window.event;
    $(this).val($(this).val().replace(/(^\s*)/g, ""))
    $(this).siblings("span.limit-text").find('.hasnum').html($(this).val().length)
}).on("keydown", ".enter-search", function(event) { //回车
    var event = event || window.event;
    var currKey = event.keyCode || event.which || event.charCode;
    if (currKey == 13) {
        var fuc = $(this).data('btn')
        $('.' + fuc).click()
    }
}).on("input keyup", ".only-num", function() { //保留整数
    var value = $(this).val();
    var RegStr = `^[\\+\\-]?\\d+\\d{0,0}`;
    $(this).val(value.match(new RegExp(RegStr, 'g')));
}).on("input keyup", ".point-num", function() { //保留data-point位小数点
    var value = $(this).val();
    var pointNum = $(this).attr("data-point") || 2;
    var RegStr = pointNum == 0 ? `^[\\+\\-]?\\d+\\d{0,0}` : `^[\\+\\-]?\\d+\\.?\\d{0,${pointNum}}`;
    if ($(this).attr("minus") && value == "-") {
        $(this).val(value);
    } else {
        $(this).val(value.match(new RegExp(RegStr, 'g')));
    }
}).on("blur", ".point-num", function() {
    if ($(this).attr("minus") && value == "-") {
        $(this).val("");
    }
});
(function($) {
    $.fn.extend({ //为扩展jQuery类本身.即对jQuery.prototype进得扩展，就是为jQuery类添加新方法。
        limitTextNum() { ///class:text-num,限制字数data-text//用于textarea提醒已输入字数
            var $that = $(this)
            var ableNum = $that.attr('data-text')
            $(this).attr('maxlength', ableNum).css('resize', 'none').css('display', 'block')
            $(this).parent().css('position', 'relative')
            setTimeout(function() {
                if ($that.parent().find(".limit-text").length == 0) {
                    $(`<span class="limit-text">
                        <span class="hasnum num-count">${$that.val() ? $that.val().length : "0"}</span>/<span class="ablenum num-count">${ableNum}</span>
                    </span>`).appendTo($that.parent()).css({
                        'display': 'inline-block',
                        'font-size': func.isPC() ? '12px' : '.24rem',
                        'color': '#999999',
                        'position': 'absolute',
                        'bottom': $that.attr('data-bottom') ? $that.attr('data-bottom') + (func.isPC() ? 'px' : 'rem') : '2px',
                        'right': $that.attr('data-right') ? $that.attr('data-right') + (func.isPC() ? 'px' : 'rem') : '18px',
                        'width': 'auto'
                    })
                }
            })
        }
    });
})(window.Zepto || window.jQuery);

$(window).ready(function() {
    for (var i = 0; i < $(".text-num").length; i++) {
        $(".text-num").eq(i).limitTextNum();
    }
});
$(function() {
    $("input").attr("autocomplete", "off")
})