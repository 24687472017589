/*地区类*/
function place(AreaCode, Name) {
    this.AreaCode = AreaCode; //地区编码  
    this.Name = Name; //地名  
    /*地区类的PlaceType方法，返回值：String类型，表示地区类别，"p"代表省/直辖市、特别行政区，"c"代表市，"d"代表区/县。*/
    place.prototype.PlaceType = function() {
            var ac = parseInt(this.AreaCode);
            if (ac % 100 != 0) {
                return "d";
            } else if (ac % 10000 != 0) {
                return "c";
            } else {
                return "p";
            }
        }
        /*返回地点所属省编码*/
    place.prototype.ProvinceCode = function() {
            //整除10000得到省级编码（前2位数字）  
            var ac = parseInt(this.AreaCode);
            return Math.floor(ac / 10000);
        }
        /*返回地点所属市编码*/
    place.prototype.CityCode = function() {
        //整除100得到市级编码（前4位数字）  
        var ac = parseInt(this.AreaCode);
        return Math.floor(ac / 100);
    }

}

var provinces_01 = new Array(); //省数组  
var cities_01 = new Array(); //市数组  
var districts_01 = new Array(); //区数组  

var provinces_02 = new Array(); //省数组  
var cities_02 = new Array(); //市数组  
var districts_02 = new Array(); //区数组  

var provinces_03 = new Array(); //省数组  
var cities_03 = new Array(); //市数组  
var districts_03 = new Array(); //区数组

/*initSeletList()这个函数初始化上面这三个数组，还有省下拉列表*/
function initSeletList_01() {
    //遍历placesMap这个Json对象,根据key：value对创建place对象，并根据地区类型分类  
    for (var key in placesMap) {
        var pl = new place(key, placesMap[key]);
        var ty = pl.PlaceType();
        if (ty == "p") {
            provinces_01.push(pl);
        }
        if (ty == "c") {
            cities_01.push(pl);
        }
        if (ty == "d") {
            districts_01.push(pl);
        }
    }
    //初始化省下拉选择列表  
    for (var i = 0; i < provinces_01.length; i++) {
        var op = document.createElement("option");
        op.text = provinces_01[i].Name;
        op.value = provinces_01[i].ProvinceCode() * 10000;
        document.getElementById("province_01").appendChild(op);
    }
}

function initSeletList_02() {
    //遍历placesMap这个Json对象,根据key：value对创建place对象，并根据地区类型分类  
    for (var key in placesMap) {
        var pl = new place(key, placesMap[key]);
        var ty = pl.PlaceType();
        if (ty == "p") {
            provinces_02.push(pl);
        }
        if (ty == "c") {
            cities_02.push(pl);
        }
        if (ty == "d") {
            districts_02.push(pl);
        }
    }
    //初始化省下拉选择列表  
    for (var i = 0; i < provinces_02.length; i++) {
        var op = document.createElement("option");
        op.text = provinces_02[i].Name;
        op.value = provinces_02[i].ProvinceCode() * 10000;
        document.getElementById("province_02").appendChild(op);
    }
}

/*下拉列表选项改变时执行此函数*/
function changeSelect_01(element) {

    var id = element.getAttribute("id");
    /*省下拉列表改变时更新市下拉列表和区下拉列表*/
    if (id == "province_01" && element.value != "000000") {
        document.getElementById("city_01").options.length = 1; //清除市下拉列表旧选项  
        var pCode = parseInt(element.value / 10000); //获取省下拉列表被选取项的省编码  
        /*根据省编码更新市下拉列表*/
        if (pCode != 0) {
            for (var i = 0; i < cities_01.length; i++) {
                if (cities_01[i].ProvinceCode() == pCode) {
                    var op = document.createElement("option");
                    op.text = cities_01[i].Name;
                    op.value = cities_01[i].CityCode() * 100;
                    document.getElementById("city_01").appendChild(op);
                }
            }
        }
        document.getElementById("district_01").options.length = 1; //清除区下拉列表旧选项  
        var cCode = parseInt(document.getElementById("city_01").value / 100); //获取市下拉列表被选中项的市编码  
        /*根据市编码更新区下拉列表*/
        if (cCode != 0) {
            for (var i = 0; i < districts_01.length; i++) {
                if (districts_01[i].CityCode() == cCode) {
                    var op = document.createElement("option");
                    op.text = districts_01[i].Name;
                    op.value = districts_01[i].AreaCode;
                    document.getElementById("district_01").appendChild(op);
                }
            }
        }
    }
    /*市下拉列表改变时更新区下拉列表的选项*/
    if (id == "city_01" && element.value != "000000") {
        document.getElementById("district_01").options.length = 1; //清除区下拉列表旧选项  
        var cCode = parseInt(element.value / 100); //获取市下拉列表被选中项的市编码  
        /*根据市编码更新区下拉列表*/
        for (var i = 0; i < districts_01.length; i++) {
            if (districts_01[i].CityCode() == cCode) {
                var op = document.createElement("option");
                op.text = districts_01[i].Name;
                op.value = districts_01[i].AreaCode;
                document.getElementById("district_01").appendChild(op);
            }
        }
    }
}

/*下拉列表选项改变时执行此函数*/
function changeSelect_02(element) {
    var id = element.getAttribute("id");
    /*省下拉列表改变时更新市下拉列表和区下拉列表*/
    if (id == "province_02" && element.value != "000000") {
        document.getElementById("city_02").options.length = 1; //清除市下拉列表旧选项  
        var pCode = parseInt(element.value / 10000); //获取省下拉列表被选取项的省编码  
        /*根据省编码更新市下拉列表*/
        if (pCode != 0) {
            for (var i = 0; i < cities_02.length; i++) {
                if (cities_02[i].ProvinceCode() == pCode) {
                    var op = document.createElement("option");
                    op.text = cities_02[i].Name;
                    op.value = cities_02[i].CityCode() * 100;
                    document.getElementById("city_02").appendChild(op);
                }
            }
        }
        document.getElementById("district_02").options.length = 1; //清除区下拉列表旧选项  
        var cCode = parseInt(document.getElementById("city_02").value / 100); //获取市下拉列表被选中项的市编码  
        /*根据市编码更新区下拉列表*/
        if (cCode != 0) {
            for (var i = 0; i < districts_02.length; i++) {
                if (districts_02[i].CityCode() == cCode) {
                    var op = document.createElement("option");
                    op.text = districts_02[i].Name;
                    op.value = districts_02[i].AreaCode;
                    document.getElementById("district_02").appendChild(op);
                }
            }
        }
    }


    /*市下拉列表改变时更新区下拉列表的选项*/
    if (id == "city_02" && element.value != "000000") {
        document.getElementById("district_02").options.length = 1; //清除区下拉列表旧选项  
        var cCode = parseInt(element.value / 100); //获取市下拉列表被选中项的市编码  
        /*根据市编码更新区下拉列表*/
        for (var i = 0; i < districts_02.length; i++) {
            if (districts_02[i].CityCode() == cCode) {
                var op = document.createElement("option");
                op.text = districts_02[i].Name;
                op.value = districts_02[i].AreaCode;
                document.getElementById("district_02").appendChild(op);
            }
        }
    }
}

function getAreaNameByKey(key) {
    return placesMap[key];
}

function getAreaName_01(districtKey) {
    initSeletList_01();
    if (districtKey == "") {
        return;
    }
    /*省*/
    var provinceKey = parseInt(districtKey / 10000) * 10000;
    var provinceOption = $("#province_01 option[value=" + provinceKey + "]");
    $("#province_01 option[value='']").removeAttr('selected');
    provinceOption.attr('selected', 'selected');


    if (districtKey % 10000 == 0) {
        changeSelect_01($("#province_01").get(0));
        return;
    }

    /*市*/
    changeSelect_01($("#province_01").get(0));

    var cityKey = parseInt(districtKey / 100) * 100;
    var provinceOption = $("#city_01 option[value=" + cityKey + "]");
    $("#city_01 option[value='']").removeAttr('selected');
    provinceOption.attr('selected', 'selected');


    if (districtKey % 100 == 0) {
        changeSelect_01($("#city_01").get(0));
        return;
    }
    /*区*/
    changeSelect_01($("#city_01").get(0));
    var provinceOption = $("#district_01 option[value=" + districtKey + "]");
    $("#district_01 option[value='']").removeAttr('selected');
    provinceOption.attr('selected', 'selected');

}

function getCityName_01(cityKey) {
    if (cityKey == "") {
        return;
    }
    /*省*/
    var provinceKey = parseInt(cityKey / 10000) * 10000;
    var provinceOption = $("#province_01 option[value=" + provinceKey + "]");
    $("#province_01 option").removeAttr('selected');
    provinceOption.attr('selected', 'selected');
    $("#province_01").val(provinceKey)
    if (cityKey % 10000 == 0) {
        changeSelect_01($("#province_01").get(0));
        return;
    }
    /*市*/
    changeSelect_01($("#province_01").get(0));

    var provinceOption = $("#city_01 option[value=" + cityKey + "]");
    $("#city_01").val(cityKey)
    $("#city_01 option[value='']").removeAttr('selected');
    provinceOption.attr('selected', 'selected');
}

function getProvinceName_01(provinceKey) {
    if (provinceKey == "") {
        return;
    }
    var provinceOption = $("#province_01 option[value=" + provinceKey + "]");
    $("#province_01 option").removeAttr('selected');
    provinceOption.attr('selected', 'selected');
    $("#province_01").val(provinceKey)
}

function getCodeByName(name) {
    var code;
    $.each(placesMap, function(index, data) {
        if (data == name) {
            code = index;
        }
    });
    return code;
}