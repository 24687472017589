//location.origin兼容
if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
};
(function($) {
    $.fn.extend({ //为扩展jQuery类本身.即对jQuery.prototype进得扩展，就是为jQuery类添加新方法。
        isShow() { //显示与否
            return $(this).css('display') == "none" ? false : true
        },
        addSvgClass(className) {
            return this.each(function() {
                var attr = $(this).attr('class') || "";
                if (!$(this).hasClass(className)) {
                    $(this).attr('class', $.trim(attr + ' ' + className))
                }
            })
        },
        removeSvgClass(className) {
            return this.each(function() {
                var attr = $(this).attr('class') || "";
                if ($(this).hasClass(className)) {
                    $(this).attr('class', attr.split(" ").filter(item => {
                        return item != className;
                    }).join(" "))
                }
            })
        }
    });
    $.extend({ //为jQuery类添加添加类方法，可以理解为添加静态方法。、
        svg(tagName) {
            return $(document.createElementNS("http://www.w3.org/2000/svg", tagName));
        },
        isEmpty(val, define = "--") {
            return (val != null && val !== "") ? val : define
        },
        isUnit(val, unit, define = "--") {
            return val != null ? val + unit : define
        },
        isZero(val) {
            return val != null ? val : 0
        },
        getUrlQuery(name) { //获取url参数
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            return r != null ? decodeURIComponent(r[2]) : ""
        },
        addZero(temp) { //个位时间加0前缀
            return (temp < 10 ? "0" : "") + temp;
        },
        getTimeStap(val) {
            return isNaN(val) ? (val ? Date.parse(val.replace(new RegExp(/-/gm), "/")) : "") : val
        },
        getDateformat(val, mark = "-") {
            if (val) {
                var dd = new Date($.getTimeStap(val))
                var y = dd.getFullYear();
                var m = $.addZero(dd.getMonth() + 1);
                var d = $.addZero(dd.getDate());
                return y + mark + m + mark + d;
            } else {
                return "";
            }
        },
        getDateformatMonth(val, mark = "-") {
            if (val) {
                var dd = new Date($.getTimeStap(val))
                var y = dd.getFullYear();
                var m = $.addZero(dd.getMonth() + 1);
                return y + mark + m;
            } else {
                return "";
            }
        },
        getDateformatCn(val, format) {
            var dd = new Date($.getTimeStap(val))
            var y = dd.getFullYear();
            var m = $.addZero(dd.getMonth() + 1);
            var d = $.addZero(dd.getDate());
            format = format.replace(/yyyy/gi, y).replace(/mm/gi, m).replace(/dd/gi, d)
            return format;
        },
        getDateformatT(val, mark = "-") {
            if (val) {
                var dd = new Date($.getTimeStap(val))
                var y = dd.getFullYear();
                var m = $.addZero(dd.getMonth() + 1);
                var d = $.addZero(dd.getDate());
                var hours = $.addZero(dd.getHours());
                var minutes = $.addZero(dd.getMinutes());
                var seconds = $.addZero(dd.getSeconds());
                return y + mark + m + mark + d + " " + hours + ":" + minutes + ":" + seconds;;
            } else {
                return "";
            }
        },
        getDateformatMT(val, mark = "-") {
            if (val) {
                var dd = new Date($.getTimeStap(val))
                var m = $.addZero(dd.getMonth() + 1);
                var d = $.addZero(dd.getDate());
                return m + mark + d;
            } else {
                return "";
            }
        },
        getDateformatHm(val) {
            if (val) {
                var dd = new Date($.getTimeStap(val))
                var hours = $.addZero(dd.getHours());
                var minutes = $.addZero(dd.getMinutes());
                return hours + ":" + minutes;
            } else {
                return "";
            }
        },
        getBeforeDay(dayNum = 0, mark = "-") { //固定格式以前以后时间 参数：差价天数+-
            var dd = new Date();
            dd.setDate(dd.getDate() + dayNum);
            var y = dd.getFullYear();
            var m = $.addZero(dd.getMonth() + 1);
            var d = $.addZero(dd.getDate());
            return y + mark + m + mark + d;
        },
        getBeforeDayT(dayNum = 0, mark = "-") {
            var dd = new Date();
            dd.setDate(dd.getDate() + dayNum);
            var y = dd.getFullYear();
            var m = $.addZero(dd.getMonth() + 1);
            var d = $.addZero(dd.getDate());
            var hours = $.addZero(dd.getHours());
            var minutes = $.addZero(dd.getMinutes());
            var seconds = $.addZero(dd.getSeconds());
            return y + mark + m + mark + d + " " + hours + ":" + minutes + ":" + seconds;
        },
        getBeforeYear(dd) {
            return new Date(dd).getFullYear()
        },
        getBeforeMonth(monthNum = 0, mark = "-") {
            var dd = new Date();
            dd.setMonth(dd.getMonth() + monthNum);
            var y = dd.getFullYear();
            var m = dd.getMonth() + 1;
            return y + mark + $.addZero(m)
        },
        getTimeSub(startVal, endVal, isEqual = true) {
            return isEqual ? $.getTimeStap(startVal) <= $.getTimeStap(endVal) : $.getTimeStap(startVal) < $.getTimeStap(endVal)
        },
        getDaySub(startVal, endVal, flag = true) {
            return ($.getTimeStap(endVal) - $.getTimeStap(startVal)) / 86400000 + 1 + (flag ? "天" : "")
        },
        getMonthSub(startVal, endVal) {
            var beforeTime = new Date($.getTimeStap(startVal))
            var lastTime = new Date($.getTimeStap(endVal))
            var y = beforeTime.getFullYear()
            var y1 = lastTime.getFullYear()
            var m = beforeTime.getMonth() + 1
            var m1 = lastTime.getMonth() + 1
            var d = beforeTime.getDate()
            var d1 = lastTime.getDate()
            var tm = 0;
            var td = 0;
            if (d < d1 + 1) {
                if (d == 1 && d1 == new Date(y1, m1, 0).getDate()) {
                    tm = (y1 - y - 1) * 12 + (m1 + 12 - m) + 1
                } else {
                    tm = (y1 - y - 1) * 12 + (m1 + 12 - m)
                    td = d1 - d + 1
                }
            } else if (d > d1 + 1) {
                if (m1 > m) {
                    tm = (y1 - y) * 12 + (m1 - m - 1)
                    td = (d1 - d + 1 + new Date(y1, m1 - 1, 0).getDate())
                } else {
                    tm = (y1 - y - 1) * 12 + (m1 - m - 1 + 12)
                    if (m1 == 1) {
                        td = (d1 - d + 1 + new Date(y1 - 1, 12, 0).getDate())
                    } else {
                        td = (d1 - d + 1 + new Date(y1, m1 - 1, 0).getDate())
                    }
                }
            } else {
                if (m1 == m) {
                    tm = (y1 - y) * 12
                } else {
                    tm = (y1 - y - 1) * 12 + (m1 - m + 12)
                }
            }
            return {
                "md": `${tm == 0 ? "" : tm + "个月"}${td == 0 ? "" : td + "天"}`,
                "m": tm,
                "d": td
            }
        },
        getMonthDay(val) {
            var dd = new Date($.getTimeStap(val))
            var y = dd.getFullYear();
            var m = $.addZero(dd.getMonth() + 1);
            var d = new Date(y, m, 0);
            return {
                firstDay: y + '-' + m + '-01',
                lastDay: y + '-' + m + '-' + d.getDate()
            }
        },
        getMonthWeek(val) {
            var dd = new Date($.getTimeStap(val));
            var y = dd.getFullYear();
            var m = dd.getMonth();
            var d = new Date(y, m, 1);
            return d.getDay();
        },
        getDay(val = new Date()) { //某天年月日星期本月天数
            var dd = new Date($.getTimeStap(val));
            var y = dd.getFullYear();
            var m = dd.getMonth() + 1;
            var d = dd.getDate();
            var ds = new Date(y, m, 0);
            return {
                ym: y + "-" + $.addZero(m),
                d: $.addZero(d),
                y: y,
                m: m,
                ds: ds.getDate(),
                week: dd.getDay() == 0 ? 7 : dd.getDay()
            }
        },
        previewImg(url) {
            $("body").append(`<div class="preview_box" onclick="$(this).remove();">
                <img class="preview_img_rotate" src="${ydc.baseUrl}/s/images/icon_xuanzhuan_h.png"
                onclick="$.previewRotate(this)">
                <img class="preview_img" src="${url}" /></div>`)
        },
        previewRotate(e) {
            window.event.cancelBubble = true;
            var rotateNum = $(".preview_img").data("rotate") || 0;
            $(".preview_img").data("rotate", rotateNum += 90).css("transform", `rotate(${rotateNum}deg)`)
            if (rotateNum % 180 == 0) {
                $(".preview_img").css("max-width", window.innerWidth * 0.8 + 'px').css("max-height", window.innerHeight * 0.8 + 'px')
            } else {
                $(".preview_img").css("max-width", window.innerHeight * 0.8 + 'px').css("max-height", window.innerWidth * 0.8 + 'px')
            }
        }
    });
})(window.Zepto || window.jQuery);
!(function(window, undefined) {
    function All() {}
    All.prototype = {
        reData(data) {
            return func.isJsonString(data) || !data ? data.replace(/"/g, '&quot;') : JSON.stringify(data).replace(/"/g, '&quot;')
        },
        convertBase64UrlToBlob(urlData) { //将以base64的图片url数据转换为Blob
            //去掉url的头，并转换为byte
            var bytes = window.atob(urlData.split(',')[1]);
            //处理异常,将ascii码小于0的转换为大于0
            var ab = new ArrayBuffer(bytes.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            var blob = new Blob([ab], {
                type: 'image/jpg'
            });
            return blob;
        },
        openPage(url) {
            var tempwindow = window.open();
            tempwindow.location = url;
        },
        isMobileNum(num) { //手机号校验
            return /^1[3-9]{1}[0-9]{1}\d{8}$/.test(num)
        },
        isTelNum(num) {
            return func.isMobileNum(num) || /^0\d{3}-?\d{7}$|^0\d{2}-?\d{8}$/.test(num)
        },
        isPC() {
            var userAgentInfo = navigator.userAgent,
                Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            return Agents.every(item => {
                return userAgentInfo.indexOf(item) == -1
            });
        },
        unique(arr) { //(a,b)(c,d)//数组去重 参数arr： 需要去重的参数
            if (!Array.isArray(arr)) {
                console.log('type error!')
                return
            }
            var array = [];
            for (var i = 0; i < arr.length; i++) {
                if (array.indexOf(arr[i]) === -1) {
                    array.push(arr[i])
                }
            }
            return array;
        },
        loadScript(url, callback) { //实现 JavaScript 文件的动态加载
            var script = document.createElement("script")
            script.type = "text/javascript";
            if (script.readyState) { //IE
                script.onreadystatechange = function() {
                    if (script.readyState == "loaded" || script.readyState == "complete") {
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else { //Others
                script.onload = function() {
                    callback();
                };
            }
            script.src = url;
            document.getElementsByTagName_r("head")[0].appendChild(script);
        },
        setCookie(val) { //cookie设置[{key:value}]、获取key、清除['key1','key2']
            for (var i = 0, len = val.length; i < len; i++) {
                for (var key in val[i]) {
                    document.cookie = key + '=' + encodeURIComponent(val[i][key]) + "; path=/";
                }
            }
        },
        // 获取hash值
        getHashParameters() {
            var arr = (location.hash || "").replace(/^\#/, '').split("&");
            var params = {};
            for (var i = 0; i < arr.length; i++) {
                var data = arr[i].split("=");
                if (data.length == 2) {
                    params[data[0]] = data[1];
                }
            }
            return params;
        },
        setParamCookie(val) {
            val = typeof val === 'string' ? JSON.parse(val) : val;
            for (var i in val) {
                val[i] == "" && delete val[i];
            }
            document.cookie = window.location.pathname + '=' + encodeURIComponent(JSON.stringify(val)) + "; path=/";
        },
        getCookie(name) {
            var strCookie = document.cookie;
            var arrCookie = strCookie.split("; ");
            for (var i = 0, len = arrCookie.length; i < len; i++) {
                var arr = arrCookie[i].split("=");
                if (name == arr[0]) {
                    return decodeURIComponent(arr[1]);
                }
            }
        },
        clearCookie(name) {
            var myDate = new Date();
            myDate.setTime(-1000); //设置时间    
            for (var i = 0, len = name.length; i < len; i++) {
                document.cookie = "" + name[i] + "=''; path=/; expires=" + myDate.toGMTString();
            }
        },
        stopDrop(el = 'body') { //禁止微信浏览器下拉回弹效果
            var lastY; //最后一次y坐标点 
            $(el).on('touchstart', function(event) {
                lastY = event.originalEvent.changedTouches[0].clientY; //点击屏幕时记录最后一次Y度坐标。 
            });
            $(el).on('touchmove', function(event) {
                var y = event.originalEvent.changedTouches[0].clientY;
                var st = $(this).scrollTop(); //滚动条高度  
                if (y >= lastY && st <= 10) { //如果滚动条高度小于0，可以理解为到顶了，且是下拉情况下，阻止touchmove事件。 
                    lastY = y;
                    event.preventDefault();
                }
                lastY = y;
            });
        },
        backTop(top = 0) { // 回到顶部 
            $('body,html').animate({
                scrollTop: top
            }, 300);
        },
        httpHtml(val) { //var v = "欢迎访问我的个人网站：http://www.zhangxinxu.com/";alert(v.httpHtml());欢迎访问我的个人网站：<a href="http://www.zhangxinxu.com/">http://www.zhangxinxu.com/</a>
            var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
            return val.replace(reg, '<a href="$1$2">$1$2</a>');
        },
        doLoop() { //发送手机验证码
            nums--;
            if (nums > 0) {
                btn.html(nums + 's');
            } else {
                clearInterval(clock); //清除js定时器
                btn.attr('disabled', false); //将按钮置为不可点击
                btn.html('点击重新发送');
                nums = 60; //重置时间
            }
        },
        urlReplace(url, title) { //修改路径不刷新页面
            var pageTitle = $('head title').text();
            if (history.pushState) {
                if (title) {
                    pageTitle = title;
                } else {
                    title = pageTitle;
                }
                history.pushState({
                    title: title
                }, title, url);
                return true;
            }
            location.href = url;
            return false;
        },
        table: [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '/'
        ],
        topDrop() {
            var lastY; //最后一次y坐标点
            $(document.body).on('touchstart', function(event) {
                lastY = event.originalEvent.changedTouches[0].clientY; //点击屏幕时记录最后一次Y度坐标。
            });
            $(document.body).on('touchmove', function(event) {
                var y = event.originalEvent.changedTouches[0].clientY;
                var st = $(this).scrollTop(); //滚动条高度  
                if (y >= lastY && st <= 10) { //如果滚动条高度小于0，可以理解为到顶了，且是下拉情况下，阻止touchmove事件。
                    lastY = y;
                    event.preventDefault();
                }
                lastY = y;
            });
        },
        decode(str) {
            if (!str) {
                return '';
            }
            var len = str.length;
            var i = 0;
            var res = [];
            while (i < len) {
                var code1 = this.table.indexOf(str.charAt(i++));
                var code2 = this.table.indexOf(str.charAt(i++));
                var code3 = this.table.indexOf(str.charAt(i++));
                var code4 = this.table.indexOf(str.charAt(i++));
                var c1 = (code1 << 2) | (code2 >> 4);
                var c2 = ((code2 & 0xF) << 4) | (code3 >> 2);
                var c3 = ((code3 & 0x3) << 6) | code4;
                res.push(String.fromCharCode(c1));
                if (code3 != 64) {
                    res.push(String.fromCharCode(c2));
                }
                if (code4 != 64) {
                    res.push(String.fromCharCode(c3));
                }
            }
            return this.UTF8ToUTF16(res.join(''));
        },
        isArr(o) {
            return Object.prototype.toString.call(o) == '[object Array]';
        },
        isJsonString(str) {
            try {
                if (typeof JSON.parse(str) == "object") {
                    return true;
                }
            } catch (e) {}
            return false;
        },
        UTF8ToUTF16(str) {
            var res = [],
                len = str.length;
            var i = 0;
            for (var i = 0; i < len; i++) {
                var code = str.charCodeAt(i);
                if (((code >> 7) & 0xFF) == 0x0) { // 对第一个字节进行判断  
                    res.push(str.charAt(i));
                } else if (((code >> 5) & 0xFF) == 0x6) {
                    var code2 = str.charCodeAt(++i);
                    var byte1 = (code & 0x1F) << 6;
                    var byte2 = code2 & 0x3F;
                    var utf16 = byte1 | byte2;
                    res.push(Sting.fromCharCode(utf16));
                } else if (((code >> 4) & 0xFF) == 0xE) {
                    var code2 = str.charCodeAt(++i);
                    var code3 = str.charCodeAt(++i);
                    var byte1 = (code << 4) | ((code2 >> 2) & 0x0F);
                    var byte2 = ((code2 & 0x03) << 6) | (code3 & 0x3F);
                    utf16 = ((byte1 & 0x00FF) << 8) | byte2
                    res.push(String.fromCharCode(utf16));
                }
            }
            return res.join('');
        }
    };
    window.func = new All();
    Array.prototype.max = function() {
        return Math.max(...this)
    };
    Array.prototype.min = function() {
        return Math.min(...this)
    };
    Array.prototype.indexOf = function(v) {
        for (var i = 0, n = this.length; i < n; i++) {
            if (this[i] == v) return i;
        }
        return -1;
    };
    Array.prototype.remove = function(val) {
        var index = this.indexOf(val);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
    Array.prototype.myfilter = function(fun /*, thisp*/ ) {
        var len = this.length;
        if (typeof fun != "function") {
            throw new TypeError();
        }
        var res = new Array();
        var thisp = arguments[1];
        for (var i = 0; i < len; i++) {
            if (i in this) {
                var val = this[i]; // in case fun mutates this  
                if (fun.call(thisp, val, i, this)) {
                    res.push(val);
                }
            }
        }
        return res;
    };
    Number.prototype.keepTwo = function(num = 0) {
        var number = Number(this)
        return Number(number.toFixed(num))
    }
})(window);