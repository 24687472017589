//全选效果	
$("table thead th input:checkbox").on("click", function() {
    $(this).closest("table").find("tr > td:first-child input:checkbox").prop("checked", $("table thead th input:checkbox").prop("checked"));
});

//滑出动效
$('.hc').on('click', function() {
    $('.hc_main').addClass('active');
});
$(window).ready(function() {
    $('.hc_bg').on('click', function() {
        $('.hc_main').removeClass('active');
    });
    $(".dialog__overlay,.action,.del_btn").click(function() {
        $(this).DialogShow({ 'class': "dialog", 'dialogFx': '0', 'id': dialogFx.options.id });
        $(".zdts").fadeOut(); //机会
        $("#lixiang").val(0); //资源库  
        $(".more-cancel").val("") //合同
    });
})
$('.hc_h1 a').on('click', function() {
    $(this).parent().find('.tc_btn').removeClass('active');
    $(this).addClass('active');
});
//表单弹出区分控制
$('.zwkh').click(function() {
    $('#tc1').fadeIn();
});
$('.delete').click(function() {
    $('#tc1').fadeOut();
});
$('.kehu_t').click(function() {
    $('#tc1').fadeOut();
    $('#kehu_t').fadeIn();
});
$('.btn_back').click(function() {
    $('#kehu_t').slideUp();
    $('#tc1').slideDown();
});

//线索详情
$(window).ready(function() {
    $('.click').click(function() {
        $(this).parent().parent().parent().parent().find('.click').removeClass('active');
        $(this).addClass('active');
        $(this).find('input').val($(this).find('.nr').text());
    });
    $(".menu-list").on("click", function() {
        event.stopPropagation();
    });
    $(document).click(function() {
        $(".menu-list ul").hide();
    });
})
$('.click input').on('blur', function(e) {
    var val = $(this).val();
    $(this).parent().parent().find('.nr').html(val);
});
$('.click .clear_input').on('blur', function(e) {
    var val = $(this).val();
    $(this).parent().parent().find('.nr').html(val);
});
$('.click select.clear_input').on('blur', function(e) {
    var val = $(this).find("option:selected").text();
    $(this).parent().parent().find('.nr').html(val);
});

function autoWidth() {
    var w = $(window).width();
    var wd = $(".main:not(#warehouse-main),.main_eachart,.hc_main");
    if ($("#menu").width() == 1) {
        wd.css({
            "overflow": "auto",
            "position": "fixed",
            "width": w - 40,
        });
    } else {
        wd.css({
            "overflow": "auto",
            "position": "fixed",
            "width": w - 260,
        });
    }

};
//监听左侧导航高度


function autoHeight() {
    var h = $(window).height();
    if (h > 300) {
        $("#left_h").css('height', h - 90 + "px");
    } else {
        return false;
    }

    if (h > 10) {
        $(".main:not(#warehouse-main)").css('height', h - 100 + "px");
        $(".hc_main").css('height', h - 150 + "px");
    } else {
        $(".main:not(#warehouse-main),.hc_main").css('height', h);
    }
}
$(function() {
    $(`<div class="showNav" onclick="hideNav(this,1)">《</div>`).appendTo("#menu")
    autoWidth();
    autoHeight();
    $(window).resize(autoWidth);
    $(window).resize(autoHeight);
});

//权限管理详情右侧宽度
$(function() {
    $(".diy_nr").width($(window).width() - 540 + "px")
    $(window).resize(function() {
        $(".diy_nr").width($(window).width() - 540 + "px")
    });
});

function tr_click() {
    $('.table tr').click(function() {
        $(this).parent().find('tr').removeClass('active');
        $(this).addClass('active');
    });
};

//设置表格<th>最小宽度
$(function() {
    $('.table tr:first th').each(function() {
        if (!$(this).html().indexOf("<") != -1) {
            var i_th = $(this).text().length * 14;
            $(this).css('min-width', i_th)
        }
    });
    $("#firstTh").css("min-width", "10px")
})

//2021.7.6左侧导航可隐藏显示
function hideNav(obj, id) {
    if (id == 1) {
        $(obj).html("》");
        $(obj).css("text-indent", "2px")
        $(obj).attr("onclick", "hideNav(this,2)")
        $("#menu").addClass("overhide")
        $(".main").css("width", $(window).width() - 40)
    } else {
        $(obj).html("《");
        $(obj).css("text-indent", "-6px")
        $(obj).attr("onclick", "hideNav(this,1)")
        $("#menu").removeClass("overhide")
        $(".main").css("width", $(window).width() - 260)

    }

}