$(".login").click(function() {
    var urlhost = window.parent.document.referrer;
    console.log(urlhost);
    var loginContent = $("input[name='loginContent']").val();
    var loginPwd = $("input[name='loginPwd']").val();
    var loginType = 0;
    $.ajax({
        type: "POST",
        url: "/login/enter",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify({
            "returnUrl": urlhost,
            "loginType": loginType,
            "loginContent": loginContent,
            "loginPwd": loginPwd
        }),
        success: function(data) {
            if (data.code != 200) {
                ydc.tip.showError(data.msg);
            } else {
                var loginResult = data.data;
                var returnUri = loginResult.returnUri;
                console.log(returnUri)
                if (null != returnUri) {
                    window.location.href = returnUri;
                }
            }
        },
        error: function(data) {
            ydc.tip.showError(JSON.parse(data.responseText).msg);
        }
    });
});

$(function() {
    if (depict) {
        ydc.tip.showError(depict);
    }
})