/**
 * module : 父
 * action : 子
 */
function EventElement(module, action) {
    var o = new Object();
    o.module = module;
    o.action = action;
    o.show = false;
    o.checkRoleEvent = function() {
        ydc.list.get({
            async: false,
            url: "/system/role/event/check?module=" + module + "&action=" + action,
        }, data => {
            o.show = data.has;
        });
        return this.show;
    }
    return o;
}